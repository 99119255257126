import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Header from '../components/header';
import ContactForm from '../components/ContactForm';
import { Container, ContentContainer } from '../components/layoutComponents';
import SEO from "../components/seo";
import { keywords } from '../constants/Keywords';

const Contact = ({ location, data }) => {
    const [ show, setShow ] = useState(false);
    return (
        <React.Fragment>
            <SEO title="Contact Us" keywords={keywords} />
            <Header path={location.pathname} />
            <Container>
                <ContentContainer padding="8rem 0 2rem">
                    <h1>Contact Us</h1>
                    <ContactForm path={location.pathname} />
                    <StyledFlex show={show}>
                        <LinkButton onClick={() => setShow(() => !show)}>
                            <Img alt="(828)367-7679" style={{ width: '80%' }} imgStyle={{margin: '0'}} fluid={data.phone_icon.childImageSharp.fluid} />
                        </LinkButton>
                        <a href="tel:‪(828)367-7679‬">
                            (828)367-7679‬
                        </a>
                        <a 
                            href="https://www.facebook.com/Darwin-Domes-132599230107908/" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >‪
                            <Img style={{ width: '100%' }} fluid={data.fb_logo.childImageSharp.fluid} />
                        </a>
                    </StyledFlex>
                    
                </ContentContainer>
            </Container>
        </React.Fragment>
    )
}

export const query = graphql`
    query {
        fb_logo: file(relativePath: { eq: "fb_logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 250, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        phone_icon: file(relativePath: { eq: "phone_icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 250, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const StyledFlex = styled.div`
    align-items: center;
    display: flex;
    > * {
        flex: 0 0 10%;
    }

    > a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a:nth-child(2) {
        visibility: ${props => props.show ? 'visible' : 'hidden'};
        opacity: ${props => props.show ? '1' : '0'};
        margin: ${props => props.show ? '0 .75rem 0 .4rem' : '0'};
        padding: ${props => props.show ? '0 1rem' : '0'};
        white-space: nowrap;
        max-width: ${props => props.show ? '500px' : '0px'};
        transition: all .5s ease-in-out;
    }
`

const LinkButton = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;

    :focus {
        outline: none;
    }
`;

export default Contact;